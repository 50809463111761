<template>
  <b-card class="blog-edit-wrapper">
    <!-- media -->
    <b-media
      no-body
      vertical-align="center"
    >
      <b-media-aside class="mr-75">
        <b-avatar
          size="38"
          :src="
            userData.profileImage
              ? `/uploads/profileimage/3/${userData.profileImage}`
              : require('@/assets/images/avatars/avatar.jpeg')
          "
        />
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-25">
          {{ userData.userName }}
        </h6>
        <b-card-text>{{ convertGenName(userData.genName) }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="제목"
            label-for="title"
            class="mb-2"
          >
            <b-form-input v-model="post.title" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="내용"
            label-for="contents"
            class="mb-2"
          >
            <quill-editor
              v-model="post.contents"
              :options="editorOption"
              @change="onEditorChange"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </b-form-group>
        </b-col>
        <!--
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">대표 이미지</h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-sm-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="post.featuredImage"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">
                  800x400 크기 이미지 등록시 목록에 표시됩니다.
                </small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    {{
                      featuredImage ? featuredImage.name : post.featuredImage
                    }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    id="featuredImage"
                    v-model="featuredImage"
                    accept=".jpg, .png, .gif"
                    placeholder="jpg, png, gif 이미지 선택"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
         -->
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
          >
            저장
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
          >
            취소
          </b-button>
          <!-- 목록 -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="float-right"
            @click="$router.go(-1)"
          >
            목록
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
  import {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
  } from 'bootstrap-vue';
  import hljs from 'highlight.js';
  import debounce from 'lodash/debounce';
  import { quillEditor } from 'vue-quill-editor';
  import Ripple from 'vue-ripple-directive';
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
  import { ref } from '@vue/composition-api';
  // import { initializeApp } from 'firebase/app';
  // import { getMessaging } from 'firebase/messaging';

  export default {
    components: {
      BCard,
      BMedia,
      BAvatar,
      BCardText,
      BMediaAside,
      BMediaBody,
      BForm,
      BLink,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
      quillEditor,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        config: {},
        post: {
          title: '',
          contents: '',
          featuredImage: '',
        },
        featuredImage: null,
        editorOption: {
          debug: 'log',
          theme: 'snow',
          placeholder: '내용을 입력하세요.',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['link', 'image', 'video'],
              ],
            },
          },
          syntax: {
            highlight: text => hljs.highlightAuto(text).value,
          },
        },
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      onEditorChange: debounce(function (value) {
        this.content = value.html;
      }, 466),
      onEditorBlur(editor) {
        console.log('editor blur!', editor);
      },
      onEditorFocus(editor) {
        console.log('editor focus!', editor);
      },
      onEditorReady(editor) {
        console.log('editor ready!', editor);
      },
      convertGenName(genName) {
        return typeof genName === 'number' ? `${genName}기` : genName;
      },
      async fetchData() {
        const menuid = this.$route.params.menuid;

        this.$http.get(`/menu/${menuid}`).then(res => {
          this.config = res.data;
          document.querySelector('.content-header-title').textContent =
            this.config.menuName;
        });
      },
      onSubmit(event) {
        const menuid = this.$route.params.menuid;
        const formData = new FormData();
        // formData.append('_method', 'PATCH');
        // Append the file
        formData.append('featuredImage', this.featuredImage);
        formData.append('title', this.post.title);
        formData.append('contents', this.post.contents);
        // 관계 정보
        formData.append('MenuId', menuid);
        formData.append('UserId', this.userData.id);
        formData.append('PostConfigId', this.config.PostConfigId);
        // console.log(JSON.stringify(this.post));
        // console.log(formData);

        // axios 파일업로드 헤더 설정
        this.$http.defaults.headers.post['Content-Type'] =
          'multipart/form-data';

        this.$http.post(`/post/create/${menuid}`, formData).then(res => {
          // 성공
          // if (menuid == '19') {
          //   const firebaseConfig = {
          //     apiKey: 'AIzaSyBjK-hBtGOYbMteBN_AgmvCMEACsDymFAQ',
          //     authDomain: 'friendsbook-244bc.firebaseapp.com',
          //     projectId: 'friendsbook-244bc',
          //     storageBucket: 'friendsbook-244bc.appspot.com',
          //     messagingSenderId: '569134535977',
          //     appId: '1:569134535977:web:3a8de462af5f2e20189338',
          //     measurementId: 'G-J8SK947LZ6',
          //   };
          //   // Initialize Firebase
          //   const firebaseApp = initializeApp(firebaseConfig);
          //   const messaging = getMessaging(firebaseApp);
          //   console.log(messaging);
          // }
          this.$router.replace(`/post/list/${menuid}`);
        });
      },
      onReset(event) {
        event.preventDefault();
        this.post.title = '';
        this.post.contents = '';
        this.post.featuredImage = '';
      },
    },
    setup() {
      const refInputEl = ref(null);
      const refPreviewEl = ref(null);

      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        base64 => {
          refPreviewEl.value.src = base64;
        }
      );

      return {
        refInputEl,
        refPreviewEl,
        inputImageRenderer,
      };
    },
    watch: {
      $route: function () {
        this.fetchData();
      },
    },
  };
</script>

<style lang="scss">
  // @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
  @import '@core/scss/vue/pages/page-blog.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
